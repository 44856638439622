import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
// import Description from "../components/description.js"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const About = ({ data }) => {
  return (
    <Layout pageTitle="About">
      {/* <div className="row">
        <div className="col-12 mt-3 mb-5">
          <h2 className="section-title">Professional and Academic Experience</h2>
        </div>
      </div> */}
      <div className="row">
        <section className="about-list col-12 col-md-6 col-lg-7 mb-4 mb-md-0">
          <h2 className="about-list__header m-0">Summary</h2>
          <p class="mt-5">Versatile front-end developer with more than two decades of design and 
          coding experience. Analytical problem solver, able to anticipate issues and find creative 
          solutions. Passionate about user-centered design, eager to provide better experiences for 
          all users. Adept at creating aesthetically pleasing, intuitive interfaces. Knowledgeable 
          and respectful professional, working across design and development teams. Proven 
          leadership, organizational, and project management skills.
          </p>
        </section>
        <section className="about-list col-12 col-md-5 offset-md-1 col-lg-4 mt-5 mt-md-0">
          <h2 className="about-list__header m-0">Education</h2>
          {data.degrees.edges.map(({ node }) => (
            <div className="degree-item mx-3" key={node.id}>
              <h3 className="degree-item__degree-type">{node.degree_type}</h3>
              <h4 className="degree-item__institution-date">{node.relationships.institution.name}, {node.year_received}</h4>
              <h4 className="degree-item__location-city">{node.location.city}, {node.location.state}</h4>
            </div>
          ))}
        </section>
      </div>
    </Layout>
  )
}

export default About

export const query = graphql `
query {
  degrees: allNodeEducation (
      sort: { order: DESC, fields: field_sorting_weight }
    ) {
      edges {
        node {
          id
          degree_type: field_edu_degree
          relationships {
            institution: field_edu_institution {
              name
            }
          }
          year_received: field_edu_year_received
          location: field_location_city {
            city: location_city_city
            state: location_city_state
          }
        }
      }
    }
  }
`